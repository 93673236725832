/* Provide sufficient contrast against white background */
a {
  color: #911208;
}

a:hover {
    color: #f7786e;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.page-thumb {
    padding: 8px;
    margin: 8px;
    background-color: cornflowerblue;
    text-align: center;
    position: relative;
    display: inline-block;
    overflow: hidden;
}

    .page-thumb img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
